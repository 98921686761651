import React, { useState } from 'react'
import { Button } from 'elementz'
import { Card, Flex, Text } from '../Components'
import { exportTable, FC } from '../Services'
import { cap, useCapsule } from '../State'
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker
} from 'react-simple-maps'
import { getCoord } from './countryCodes'
const geoUrl = 'https://stats.pit.family/world-110m.json'

const MapChart = () => {
  const [activity] = useCapsule(cap.activity)
  const lastSession = cap.sessions.state.find(({ sessionId }) => sessionId === activity[0].sessionId)
  const countries = cap.devices.state.reduce((acc, { country }) => {
    const index = acc.findIndex(c => c.country === country)
    if (index === -1) return [...acc, { country, tot: 1 }]
    acc[index].tot += 1
    return acc
  }, [])

  const geos = (geo) => (
    <Geography
      key={geo.rsmKey}
      geography={geo}
      fill={geo.properties.ISO_A2 === lastSession?.device?.country
        ? 'orange'
        : countries.find(({ country }) => country === geo.properties.ISO_A2) ? 'yellow' : '#EAEAEC'}
      stroke='#D6D6DA'
    />)

  return (
    <ComposableMap projection='geoMercator' projectionConfig={{ scale: 130 }} height={780} width={900}>
      <Geographies geography={geoUrl}>
        {({ geographies }) => geographies.map(geos)}
      </Geographies>

      {countries.map(({ country, tot }) => (
        <Marker key={country} coordinates={getCoord(country)}>
          <circle r={8} fill='#F00' />
          <text textAnchor='middle' y={5} style={{ fill: 'white', fontSize: 12 }}>{tot}</text>
        </Marker>
      ))}
    </ComposableMap>
  )
}

const ActLine = ({ data: { sessionId, metricsType, ...data } }) => {
  const session = cap.sessions.state.find(s => s.sessionId === sessionId) || {}
  const device = cap.devices.state.find(d => d?.id === session?.deviceId) || {}
  const phone = device.manufacturer + ' ' + device.model
  const baseInfo = `${phone} from ${device.city}, ${device.country} `
  const action = metricsType === 'device'
    ? ' STARTED the App'
    : data.activated
      ? (data.duration ? ' is using ' : ' opened ') + data.tool
      : ' closed ' + data.tool
  return (
    <Text
      color={device.appName === 'ghostfinderpro' ? 'red' : 'green'}
      value={
      baseInfo + action
    }
    />
  )
}

const Activity = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [activity] = useCapsule(cap.activity)
  const handleDownload = async () => {
    setIsLoading(true)
    const results = await FC.service('stats').get('allData')
    exportTable('allData', results)
    setIsLoading(false)
  }
  return (
    <Card style={{ width: 600, height: 780 }}>
      <Button primary disabled={isLoading} loading={isLoading} onClick={handleDownload}>Ottieni statistiche complete</Button>
      <div style={{ height: 20 }} />
      {activity.map((data, index) => <ActLine data={data} key={`act${index}`} />)}
    </Card>
  )
}

export const Dashboard = () => {
  return (
    <Flex fw fh row as style={{ padding: 20 }}>
      <div style={{ width: 1200, height: 780, overflow: 'hidden' }}>
        <MapChart />
      </div>
      <Activity />
    </Flex>
  )
}
