const countries = {
  AW: [12.5, -69.96666666],
  AF: [33, 65],
  AO: [-12.5, 18.5],
  AI: [18.25, -63.16666666],
  AX: [60.116667, 19.9],
  AL: [41, 20],
  AD: [42.5, 1.5],
  AE: [24, 54],
  AR: [-34, -64],
  AM: [40, 45],
  AS: [-14.33333333, -170],
  AQ: [-90, 0],
  TF: [-49.25, 69.167],
  AG: [17.05, -61.8],
  AU: [-27, 133],
  AT: [47.33333333, 13.33333333],
  AZ: [40.5, 47.5],
  BI: [-3.5, 30],
  BE: [50.83333333, 4],
  BJ: [9.5, 2.25],
  BF: [13, -2],
  BD: [24, 90],
  BG: [43, 25],
  BH: [26, 50.55],
  BS: [24.25, -76],
  BA: [44, 18],
  BL: [18.5, -63.41666666],
  BY: [53, 28],
  BZ: [17.25, -88.75],
  BM: [32.33333333, -64.75],
  BO: [-17, -65],
  BR: [-10, -55],
  BB: [13.16666666, -59.53333333],
  BN: [4.5, 114.66666666],
  BT: [27.5, 90.5],
  BV: [-54.43333333, 3.4],
  BW: [-22, 24],
  CF: [7, 21],
  CA: [60, -95],
  CC: [-12.5, 96.83333333],
  CH: [47, 8],
  CL: [-30, -71],
  CN: [35, 105],
  CI: [8, -5],
  CM: [6, 12],
  CD: [0, 25],
  CG: [-1, 15],
  CK: [-21.23333333, -159.76666666],
  CO: [4, -72],
  KM: [-12.16666666, 44.25],
  CV: [16, -24],
  CR: [10, -84],
  CU: [21.5, -80],
  CW: [12.116667, -68.933333],
  CX: [-10.5, 105.66666666],
  KY: [19.5, -80.5],
  CY: [35, 33],
  CZ: [49.75, 15.5],
  DE: [51, 9],
  DJ: [11.5, 43],
  DM: [15.41666666, -61.33333333],
  DK: [56, 10],
  DO: [19, -70.66666666],
  DZ: [28, 3],
  EC: [-2, -77.5],
  EG: [27, 30],
  ER: [15, 39],
  EH: [24.5, -13],
  ES: [40, -4],
  EE: [59, 26],
  ET: [8, 38],
  FI: [64, 26],
  FJ: [-18, 175],
  FK: [-51.75, -59],
  FR: [46, 2],
  FO: [62, -7],
  FM: [6.91666666, 158.25],
  GA: [-1, 11.75],
  GB: [54, -2],
  GE: [42, 43.5],
  GG: [49.46666666, -2.58333333],
  GH: [8, -2],
  GI: [36.13333333, -5.35],
  GN: [11, -10],
  GP: [16.25, -61.583333],
  GM: [13.46666666, -16.56666666],
  GW: [12, -15],
  GQ: [2, 10],
  GR: [39, 22],
  GD: [12.11666666, -61.66666666],
  GL: [72, -40],
  GT: [15.5, -90.25],
  GF: [4, -53],
  GU: [13.46666666, 144.78333333],
  GY: [5, -59],
  HK: [22.267, 114.188],
  HN: [15, -86.5],
  HR: [45.16666666, 15.5],
  HT: [19, -72.41666666],
  HU: [47, 20],
  ID: [-5, 120],
  IM: [54.25, -4.5],
  IN: [20, 77],
  IO: [-6, 71.5],
  IE: [53, -8],
  IR: [32, 53],
  IQ: [33, 44],
  IS: [65, -18],
  IL: [31.47, 35.13],
  IT: [42.83333333, 12.83333333],
  JM: [18.25, -77.5],
  JE: [49.25, -2.16666666],
  JO: [31, 36],
  JP: [36, 138],
  KZ: [48, 68],
  KE: [1, 38],
  KG: [41, 75],
  KH: [13, 105],
  KI: [1.41666666, 173],
  KN: [17.33333333, -62.75],
  KR: [37, 127.5],
  KW: [29.5, 45.75],
  LA: [18, 105],
  LB: [33.83333333, 35.83333333],
  LR: [6.5, -9.5],
  LY: [25, 17],
  LC: [13.88333333, -60.96666666],
  LI: [47.26666666, 9.53333333],
  LK: [7, 81],
  LS: [-29.5, 28.5],
  LT: [56, 24],
  LU: [49.75, 6.16666666],
  LV: [57, 25],
  MO: [22.16666666, 113.55],
  MF: [18.08333333, -63.95],
  MA: [32, -5],
  MC: [43.73333333, 7.4],
  MD: [47, 29],
  MG: [-20, 47],
  MV: [3.25, 73],
  MX: [23, -102],
  MH: [9, 168],
  MK: [41.83333333, 22],
  ML: [17, -4],
  MT: [35.83333333, 14.58333333],
  MM: [22, 98],
  ME: [42.5, 19.3],
  MN: [46, 105],
  MP: [15.2, 145.75],
  MZ: [-18.25, 35],
  MR: [20, -12],
  MS: [16.75, -62.2],
  MQ: [14.666667, -61],
  MU: [-20.28333333, 57.55],
  MW: [-13.5, 34],
  MY: [2.5, 112.5],
  YT: [-12.83333333, 45.16666666],
  NA: [-22, 17],
  NC: [-21.5, 165.5],
  NE: [16, 8],
  NF: [-29.03333333, 167.95],
  NG: [10, 8],
  NI: [13, -85],
  NU: [-19.03333333, -169.86666666],
  NL: [52.5, 5.75],
  NO: [62, 10],
  NP: [28, 84],
  NR: [-0.53333333, 166.91666666],
  NZ: [-41, 174],
  OM: [21, 57],
  PK: [30, 70],
  PA: [9, -80],
  PN: [-25.06666666, -130.1],
  PE: [-10, -76],
  PH: [13, 122],
  PW: [7.5, 134.5],
  PG: [-6, 147],
  PL: [52, 20],
  PR: [18.25, -66.5],
  KP: [40, 127],
  PT: [39.5, -8],
  PY: [-23, -58],
  PS: [31.9, 35.2],
  PF: [-15, -140],
  QA: [25.5, 51.25],
  RE: [-21.15, 55.5],
  RO: [46, 25],
  RU: [60, 100],
  RW: [-2, 30],
  SA: [25, 45],
  SD: [15, 30],
  SN: [14, -14],
  SG: [1.36666666, 103.8],
  GS: [-54.5, -37],
  SJ: [78, 20],
  SB: [-8, 159],
  SL: [8.5, -11.5],
  SV: [13.83333333, -88.91666666],
  SM: [43.76666666, 12.41666666],
  SO: [10, 49],
  PM: [46.83333333, -56.33333333],
  RS: [44, 21],
  SS: [7, 30],
  ST: [1, 7],
  SR: [4, -56],
  SK: [48.66666666, 19.5],
  SI: [46.11666666, 14.81666666],
  SE: [62, 15],
  SZ: [-26.5, 31.5],
  SX: [18.033333, -63.05],
  SC: [-4.58333333, 55.66666666],
  SY: [35, 38],
  TC: [21.75, -71.58333333],
  TD: [15, 19],
  TG: [8, 1.16666666],
  TH: [15, 100],
  TJ: [39, 71],
  TK: [-9, -172],
  TM: [40, 60],
  TL: [-8.83333333, 125.91666666],
  TO: [-20, -175],
  TT: [11, -61],
  TN: [34, 9],
  TR: [39, 35],
  TV: [-8, 178],
  TW: [23.5, 121],
  TZ: [-6, 35],
  UG: [1, 32],
  UA: [49, 32],
  UM: [19.2911437, 166.618332],
  UY: [-33, -56],
  US: [38, -97],
  UZ: [41, 64],
  VA: [41.9, 12.45],
  VC: [13.25, -61.2],
  VE: [8, -66],
  VG: [18.431383, -64.62305],
  VI: [18.35, -64.933333],
  VN: [16.16666666, 107.83333333],
  VU: [-16, 167],
  WF: [-13.3, -176.2],
  WS: [-13.58333333, -172.33333333],
  YE: [15, 48],
  ZA: [-29, 24],
  ZM: [-15, 30],
  ZW: [-20, 30]
}

export const getCoord = (country) => {
  const [lon, lat] = countries[country] || []
  if (!lat) return [0, 0]
  return [lat, lon]
}
