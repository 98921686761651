import React, { useState } from 'react'
import { Input, Button } from 'elementz'
import { Card, Flex } from '../Components'
import { doLogin } from '../State'

export const Login = () => {
  const [username, setUser] = useState('')
  const [password, setPass] = useState('')
  const [waiting, setWaiting] = useState(false)

  const handlechange = (setter) => ({ target: { value } }) => setter(value)
  const handleLogin = async () => {
    setWaiting(true)
    !(await doLogin({ strategy: 'local', username, password })) && setWaiting(false)
  }

  return (
    <Flex fw style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} bg='#171717'>
      <Card style={{ width: 300 }}>
        <Input.Group vertical space>
          <Input
            placeholder='User...'
            onChange={handlechange(setUser)}
            value={username}
            loading
          />
          <Input
            type='password'
            placeholder='Password...'
            onChange={handlechange(setPass)}
            value={password}
          />
          <Button primary effect='shrink' onClick={handleLogin} disabled={waiting} loading={waiting}>Login</Button>
        </Input.Group>
      </Card>
    </Flex>
  )
}
