import React, { useState, useEffect } from 'react'
import { Text, Flex } from './'
// import { useSpring, animated } from 'react-spring'
import { FaChevronUp } from 'react-icons/fa'

export function Card ({ fadeIn, collapsable, title, titleStyle, collapsed, children, style, customHeader, onExpand }) {
  const [state, setState] = useState('closed')
  const isCollapsed = state === 'closed'

  const toggleCollapse = (newState) => {
    const applyNewState = newState || (isCollapsed ? 'open' : 'closed')
    setState(applyNewState)
    applyNewState === 'open' && onExpand && onExpand()
  }

  useEffect(() => { collapsable && setState(collapsed ? 'closed' : 'open') }, [collapsable, collapsed])

  const cardStyle = {
    borderColor: '#f4eefb',
    border: '1px solid rgba(96, 93, 98, 1)',
    borderRadius: 20,
    padding: 20,
    ...(collapsable ? { position: 'relative' } : {}),
    ...(collapsable && isCollapsed && !customHeader ? { cursor: 'pointer' } : {}),
    ...style
  }

  return (
    <div style={cardStyle} onClick={isCollapsed && !customHeader ? () => toggleCollapse('open') : null}>
      {!customHeader && !title
        ? null
        : (
          <Flex row jb fw>
            <Flex row js>
              {title ? <Text title bold value={title} style={{ ...titleStyle, marginRight: 20 }} size={20} /> : null}
              {customHeader}
            </Flex>
            {collapsable && (
              <div pose={state} style={{ cursor: 'pointer' }} onClick={() => (customHeader || !isCollapsed) && toggleCollapse()}>
                <FaChevronUp />
              </div>
            )}
          </Flex>
          )}
      {!collapsable
        ? children
        : (
          <div style={{ overflow: 'hidden' }}>
            {children}
          </div>
          )}
    </div>
  )
}
