import { StoredCapsule, Capsule } from './StoredCapsule'
import { objectMap } from '../Services'
import _ from 'lodash'

const storedCapsules = {
  user: {
    accessToken: '',
    username: ''
  }
}

const memCapsules = {
  sessions: [],
  devices: [],
  activity: []
}

const cap = {
  ...objectMap(storedCapsules, (key, value) => new StoredCapsule(value, key)),
  ...objectMap(memCapsules, (key, value) => new Capsule(value))
}

cap.dumpStates = () => objectMap(cap, (key, value) => _.omit(value.state, 'accessToken'))
cap.resetCapsules = () => Object.values(cap).map(c => typeof cap === 'object' && c.reset && c.reset())

export { cap }
