import feathers from '@feathersjs/client'
import socketio from '@feathersjs/socketio-client'
import fcAuth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { cap } from '../State'

// import { invalidateQuery } from '.'

/* ============================== Environment Setup ================================================== */
const isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
const servers = { local: 'http://localhost:3000', production: 'https://metrics.pit.family' }
const forceLocal = false
const serverUrl = forceLocal ? servers.local : window._env_?.API_URL || servers.production
/* ============================== Socket Configuration ================================================== */
const FC = {
  isDev,
  socket: io(serverUrl, { transports: ['websocket'], forceNew: true }),
  client: feathers(),
  online: false,
  authenticated: false,
  connectionHandler: (event) => () => {
    FC.isDev && console.log(`Socket ${event} to ${serverUrl}`)
    FC.online = event === 'connect'
  }
}

FC.client.configure(socketio(FC.socket, { timeout: 30000, pingInterval: 5000, pingTimeout: 20000 }))
FC.client.configure(fcAuth({ storage: new fcAuth.MemoryStorage() }))

FC.socket.on('connect', FC.connectionHandler('connect'))
FC.socket.on('disconnect', FC.connectionHandler('disconnect'))

/* ============================== Socket Methods ================================================== */

FC.login = async (credentials) => {
  try {
    const user = await FC.client.authenticate(credentials)
    FC.authenticated = !!user.accessToken
    return user
  } catch (details) {
    FC.authenticated = false
    FC.isDev && console.log(details)
    return { user: { username: 'NotAuthenticated' } }
  }
}

FC.logout = () => {
  try { FC.client.logout() } catch (e) {}
}

const doServiceMethod = async (serviceName, method, param1, param2) => {
  try {
    return (await FC.client.service(serviceName)[method](param1, param2))
  } catch (err) {
    FC.isDev && console.log(err)
  }
}

FC.isReady = () => FC.online && FC.authenticated

FC.service = (serviceName) => ({
  find: (query) => doServiceMethod(serviceName, 'find', query),
  patch: (id, body) => doServiceMethod(serviceName, 'patch', id, body),
  get: (id, query) => doServiceMethod(serviceName, 'get', id, query),
  create: (body, query) => doServiceMethod(serviceName, 'create', body, query),
  remove: (id) => doServiceMethod(serviceName, 'remove', id),
  update: (id, body) => doServiceMethod(serviceName, 'update', id, body)
})

// Channel Updates devices, sessions
FC.client.service('metrics').on('created', (data) => {
  if (!data?.metricsType || !data?.sessionId) return false
  const setActivity = () => cap.activity.setState([data, ...(cap.activity.state.slice(0, 29))])
  if (!cap.sessions.state.find(({ sessionId }) => data.sessionId === sessionId)) {
    FC.service('stats').get('sessionInfo', { query: { sessionId: data.sessionId } }).then(({ device, session }) => {
      if (!session) return setActivity()
      if (!cap.devices.state.find(({ id }) => id === device.id)) cap.devices.setState([...cap.devices.state, device])
      cap.sessions.setState([...cap.sessions.state, { ...session, device }])
      setActivity()
    })
      .catch(() => console.log('No Device found!'))
  } else {
    setActivity()
  }
})

export { FC }
