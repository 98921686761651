import copy from 'clipboard-copy'

export const copyToClipboard = (text) => {
  copy(text)
    .then(() => window.alert('Dati copiaati negli appunti'))
    .catch(() => window.alert('Errore nella copia negli appunti'))
}

const structures = {

  allData: [
    ['Primo Contatto', v => v.crated],
    ['Ultimo Contatto', v => v.lastSeen],
    ['OS', v => v.os],
    ['Brand', v => v.brand],
    ['Costruttore', v => v.manufacturer],
    ['Modello', v => v.model],
    ['Tipo Modello', v => v.modelType],
    ['Anno Prod Cellulare', v => v.yearClass],
    ['Versione OS', v => v.osVersion],
    ['Applicazione', v => v.appName],
    ['Latitudine', v => v.lat],
    ['Longitudine', v => v.lon],
    ['Paese', v => v.country],
    ['Città', v => v.city],
    ['Lingua', v => v.language],
    ['Numero Sessioni', v => v.numSessions],
    ['Totale Sessioni (secondi)', v => v.totDuration],
    ['Totale M1 (secondi)', v => v.totm1],
    ['Totale Rem (secondi)', v => v.totRem],
    ['Totale GhostBox (secondi)', v => v.totGhost],
    ['Totale BlackBox (secondi)', v => v.totBlackBox]
  ]
}

export const exportTable = (table, data) => {
  const structure = structures[table]
  if (!structure || !data || !data.length) return null

  const header = structure.map(s => s[0]).join('\t')
  const parseLine = line => structure.map(e => e[1](line)).join('\t')

  copyToClipboard(header + '\n' + data.map(parseLine).join('\n'))
}
