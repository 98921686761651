import React from 'react'

export const Text = ({ style, bold, italic, size, color, center, upCase, value }) => (
  <div
    style={{
      fontWeight: bold ? 'bold' : 'normal',
      fontStyle: italic ? 'italic' : 'normal',
      fontSize: size || 15,
      fontFamily: '\'Source Sans Pro\', sans-serif',
      color: color || 'yellow',
      textAlign: center ? 'center' : 'start',
      ...style
    }}
  >
    {upCase && value ? value.toUpperCase() : value}
  </div>
)
