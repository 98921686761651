import { FC, prefetchQueries } from '../Services'
import { cap } from './Capsules'

export * from './Capsules'
export * from './StoredCapsule'

export const restoreState = async () => {
  const { accessToken } = cap.user.state
  if (!accessToken || !(await doLogin({ strategy: 'jwt', accessToken }))) return doLogout()
}

export const doLogin = async (auth) => {
  const { fuser: { username }, accessToken } = await FC.login(auth)
  if (!accessToken) return false

  cap.user.setState({ username, accessToken })

  prefetchQueries()
  return true
}

export const doLogout = async () => cap.resetCapsules()
