import React from 'react'
import { ReactQueryDevtools } from 'react-query-devtools'
import { useCapsule, cap } from '../State'
import { Login, Dashboard } from '../Pages'

export const App = () => {
  const [{ accessToken }] = useCapsule(cap.user)
  !window.EzTheme.isDarkMode && window.EzTheme.toggleDarkMode()
  return (
    <React.StrictMode>
      <ReactQueryDevtools initialIsOpen={false} />
      {accessToken ? <Dashboard /> : <Login />}
    </React.StrictMode>
  )
}
