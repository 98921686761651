export const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, fn(k, v)]))

export const getUrlParams = (params) => {
  if (!params) return undefined
  const url = new URL(window.location.href)
  const found = (Array.isArray(params) ? params : [params]).map((p) => url.searchParams.get(p))
  return found.length === 1 ? found[0] : found
}

export const capitalize = (word) => word ? word.toLowerCase().replace(/^\w/, c => c.toUpperCase()) : ''
export const capitalizeSentence = (par) => par ? par.split(' ').map(capitalize).join(' ') : ''

export const getScreenWidth = () => window.innerWidth || document.documentElement.clientWidth
export const getScreenHeight = () => window.innerHeight || document.documentElement.clientHeight
export const isLargeScreen = () => getScreenWidth() > 1600
